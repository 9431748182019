.help {
  font-size: 1.6rem;
  color: var(--primary-text-color);

  & a {
    font-weight: 500;
    color: var(--primary-color) !important;
  }

  & strong {
    font-weight: 600;
  }
  .title {
    margin-top: 15px;
    font-size: 1.7rem;
    font-weight: 600;
    color: var(--primary-color);

    &:first-child {
      margin-top: 0;
    }
  }

  .first-p {
    font-weight: 500;
    width: 100%;
  }

  & li {
    margin-top: 15px;
    width: 100%;

    &:first-child {
      margin-top: 5px;
    }
  }
}
