@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.list-categories {
  &__item {
    margin-bottom: 50px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 0.7s ease;

    &:hover {
      transform: scale(1.02);
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 25px;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }
}

.category-img {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
  object-fit: cover;
  overflow: hidden;
  border-radius: 4px;
  color: var(--white);

  @include media-breakpoint-down(md) {
    margin-bottom: 5px;
  }
}

.category-name {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--text-color);
}
