.messages {
  &__box {
    margin-top: 8px;
    height: 68%;
    max-height: 82%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__desc {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    gap: 0.5rem !important;
    font-size: 1.4rem !important;
    font-weight: 500 !important;
    text-align: center !important;
    color: var(--primary-bg) !important;
  }
}
