@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.message-input {
  &__container {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    min-height: 22%;
    border-radius: 0.5rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 1rem;

    &-emoji {
      position: relative;
      margin-top: 10px;

      svg {
        margin: 0 5px;
        font-size: 2rem;
        color: var(--primary-bg);
        cursor: pointer;
      }

      &-picker {
        position: absolute;
        left: 0;
        bottom: calc(100% + 20px);
        background-color: #080420;
        border-color: var(--primary-bg);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 0.82rem;
        overflow: hidden;
        z-index: 1000;

        @include media-breakpoint-down(lg) {
          left: -10rem;
          width: 300px;
          height: 400px;
        }
      }
    }
  }

  &__form {
    display: flex;
    flex-grow: 1;
    align-items: center;

    .MuiOutlinedInput-root {
      width: 100% !important;
      padding-right: 2px !important;
    }
  }

  &__input-group {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 5px;
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid var(--primary-bg);
  }

  &__preview-image {
    margin-left: 10px;
    max-height: 100px;
  }

  &__image {
    margin-top: 10px;
    max-height: 60px;
    border-radius: 0.5rem;
  }
}
