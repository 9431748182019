@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.restaurant {
  margin-top: 40px;
  margin-bottom: 80px;

  &__search-container {
    position: relative;
    margin-top: 130px;
    margin-bottom: 50px;
  }

  &__search-icon {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    color: var(--secondary-text-color);
  }

  &__search {
    padding-left: 60px;
    width: 100%;
    height: 44px;
    font-size: 1.6rem;
    border-radius: 99999px;
    background-color: #f7f7f7;
    border: 1px solid transparent;
    transition: border-color linear 0.2s;

    &:hover {
      border-color: var(--primary-color);
    }

    &::placeholder {
      color: var(--secondary-text-color);
    }
  }

  &__separate {
    width: 100%;
    height: 10px;
    background: #f7f7f7;
  }

  &__popular-title {
    margin-bottom: 30px;
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--text-color);

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }

    &--highlight {
      color: var(--primary-color);
    }
  }

  &__breadcrumb {
    margin-bottom: 20px;
  }
}
