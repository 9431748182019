@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.chat-modal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 15px 20px 20px;
  width: 800px;
  height: calc(100% - 60px);
  border-radius: 6px;
  transform: translateY(50%);
  background-color: #f5f5f5;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

  @include media-breakpoint-down(lg) {
    padding: 15px 15px 33px;
    width: calc(100% - 30px);
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 20px;
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }

  &__group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd !important;
  }

  &__title {
    font-size: 1.8rem !important;
    font-weight: 700 !important;
    line-height: 1.5 !important;
    text-align: center !important;
    letter-spacing: 0.008px !important;
    color: rgb(39, 39, 42) !important;
  }

  &__icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    cursor: pointer;
  }

  &__messages {
    position: relative;
    flex: 1;
    padding: 10px 10px 0;
    height: 250px;
    font-size: 15px;
    clear: both;
    overflow-y: auto;
  }

  &__message {
    margin-bottom: 15px !important;
    max-width: 70%;
    clear: both;

    &--chatbot {
      float: left;
      padding: 10px;
      margin-right: 10px;
      border-radius: 5px;
      background-color: #f0f0f0;
    }

    &--user {
      padding: 10px;
      margin-left: 10px;
      float: right;
      border-radius: 4px;
      background-color: rgba(0, 186, 81, 0.2);
    }
  }

  &__loading {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 0;
    clear: both;
  }

  &__divider {
    margin: 15px 0 0 0;
    border: none;
    border-top: 1px solid #ddd;
  }

  &__container {
    position: relative;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    padding: 8px 48px 8px 16px;
    width: 100%;
    height: 40px;
    min-height: 40px;
    line-height: 21px;
    color: rgb(39, 39, 42);
    border-radius: 20px;
    caret-color: var(--primary-bg);
    border: 1px solid rgb(166, 166, 176);
    resize: none;
    outline: 0px;
    appearance: none;
    transition: border linear 0.2s;

    &:hover {
      border-color: var(--primary-bg);
    }
  }

  &__input {
    position: absolute;
    top: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 60px);
    height: 100%;
    font-size: 1.5rem;
    border-color: rgb(39, 39, 42);
    transform: translateY(-50%);

    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }

    &::placeholder {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    user-select: none;
  }
}

@keyframes loading {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.dot {
  display: inline-block;
  margin: 0 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #333;
  animation: loading 1s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}
