@import 'normalize.css';
@import './FontFamily.scss';
@import './Form.scss';

:root {
  --primary-color: #00b14f;
  --primary-bg: #4d8b55;
  --font-family: 'Lora', sans-serif;
  --text-color: #2f2f2f;
  --secondary-text-color: #676767;
  --white: #fff;
  --coffee-color: #b99272;
  --coffee-color-v2: #65391c;
  --icon-color: brightness(0) saturate(100%) invert(39%) sepia(4%) saturate(0%) hue-rotate(138deg) brightness(100%)
    contrast(91%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: var(--font-family);
  line-height: 1.5;
  text-rendering: optimizeSpeed;
  color: var(--text-color);
  overflow: hidden;
}

::selection {
  background: var(--primary-bg);
  color: var(--white);
}

// Scrollbar CSS
html ::-webkit-scrollbar {
  border-radius: 0px;
  width: 8px;
}

html ::-webkit-scrollbar-thumb {
  background-color: rgba(22, 24, 35, 0.2);
}

html ::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0);
}

button,
input {
  border: none;
  outline: none;
}

input,
textarea {
  background-color: transparent;
}

button,
input,
label,
textarea,
select {
  font-family: inherit;
  color: inherit;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

img {
  user-select: none;
}

.separate {
  margin: var(--separate-mg, 24px 0 16px);
  width: 100%;
  height: 0.5px;
  background-color: var(--separate-bg, #ffffff40);
}

.dot-separate {
  margin: 0 8px;
}

.icon {
  filter: var(--icon-color);
}

.overlay {
  position: fixed;
  width: 100%;
  height: 200vh;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 999;
  transition: opacity 0.5s;

  &--show {
    opacity: 0.4;
  }
}

.modal-loading {
  position: fixed;
  inset: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.2);

  &__content {
    position: fixed;
    inset: 50%;
    z-index: 999999;
  }
}
