@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.container {
  position: relative;
  padding: 16px;
  width: 70%;

  @include media-breakpoint-down(md) {
    padding: 8px;
    width: 80%;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
  }

  &__heading {
    display: flex;
    align-items: center;
  }

  &__avatar {
    width: 30px;
    height: 30px;
  }
}

.no-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1rem;
  height: 100%;
  font-weight: 600;
  text-align: center;
  color: var(--primary-bg);
}
