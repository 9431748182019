@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.order-status {
  &__nav {
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    flex-grow: 1;
    padding: 10px 0;
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
    border-top: 2px solid rgba(0, 0, 0, 0.07);
    border-bottom: 3px solid transparent;
    transition: all linear 0.3s;
    cursor: pointer;
    user-select: none;

    @include media-breakpoint-down(lg) {
      border-top-color: transparent;
    }

    &:hover {
      color: var(--coffee-color);
    }

    &--active {
      color: var(--coffee-color);
      border-bottom: 3px solid var(--coffee-color-v2);
    }
  }
}

.empty-order {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  min-height: 350px;
  border-radius: 6px;
  background-color: var(--white);

  &__img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  &__desc {
    margin-top: 20px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--primary-bg);
  }
}
