@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

$logoWidth: 65px;

.logo {
  padding: 10px 24px;
  width: 100%;
  height: $logoWidth;
  background-color: rgb(24, 24, 24);
  box-shadow: rgb(163, 163, 163) -1px 0px 10px 0px;

  &__img {
    height: 100%;
    object-fit: cover;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - $logoWidth);
  background-image: url('../../assets/images/banner/cafe08.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
