.go-top {
  &__wrapper,
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity, visibility, transform;
    transition-timing-function: linear;
    transition-duration: 0.3s;
  }

  &__wrapper {
    position: fixed;
    top: 90vh;
    right: 2vw;
    width: 48px;
    height: 48px;
    transform: translateX(180%);
    background: conic-gradient(var(--primary-bg) var(--progressScroll), #e5e5eb 0deg);
    z-index: 99;

    &--show {
      opacity: 1;
      visibility: visible;
      transform: translateX(0%);
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 44px;
    height: 44px;
    background-color: var(--white);
    transform: translateX(-50%) translateY(-50%);
    z-index: 999;

    &--show {
      opacity: 1;
      visibility: visible;
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    rotate: 180deg;
    filter: brightness(0) saturate(100%) invert(45%) sepia(33%) saturate(585%) hue-rotate(77deg) brightness(98%)
      contrast(85%);
  }
}
