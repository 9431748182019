.infinite-scroll {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: 100%;
}

.restaurant-list__item {
  padding: 0;
}

.list__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
