@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.cart {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: min(516px, 100%);
  height: 100vh;
  background-color: var(--white);
  z-index: 9999;
  transform: translateX(100%);
  opacity: 0;
  transition: transform, opacity, visibility;
  transition-duration: 0.5s;
  visibility: hidden;

  @include media-breakpoint-down(lg) {
    position: fixed;
    width: 100%;
    overflow-y: hidden;
  }

  @include media-breakpoint-down(md) {
    transform: translateY(100%);
  }

  &--show {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);

    @include media-breakpoint-down(md) {
      transform: translateY(0);
    }
  }

  &__top {
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #f0efef;

    @include media-breakpoint-down(md) {
      padding: 8px;
    }

    &-block {
      flex: 1;
      line-height: 1.5;
    }

    &-title {
      font-size: 1.8rem;
      font-weight: 600;
    }

    &-desc {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      color: #9a9a9a;
    }

    &-clock {
      margin-right: 8px;
      width: 1.8rem;
      height: 1.8rem;
      filter: brightness(0) saturate(100%) invert(68%) sepia(14%) saturate(30%) hue-rotate(314deg) brightness(89%)
        contrast(89%);
    }
  }

  &__close {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      padding: 10px;
    }
  }

  &__container {
    height: 100%;
    flex: 1;
    overflow: auto;

    &--center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__container--center &__empty {
    transform: translateY(-25%);
  }

  &__empty {
    padding: 12px;
    width: 80%;
    max-width: 100%;
    text-align: center;

    &-img {
      visibility: visible;
      width: 50%;
      height: auto;
    }

    &-title {
      margin-top: 24px;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1.4;
    }

    &-desc {
      margin-top: 8px;
      font-size: 1.6rem;
      font-weight: 500;
      color: var(--coffee-color);
    }

    &-btn {
      margin-top: 8px;
      padding: 15px 12px;
      font-size: 1.6rem;
      font-weight: 500;
      color: var(--coffee-color-v2);
      background-color: transparent;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  &__scroll {
    overflow-y: auto;
    height: 100%;
  }

  &__content {
    padding-bottom: 500px;
    padding: 24px;
    min-height: calc(100% - 52px);
  }

  &__products {
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-name {
      display: flex;
      align-items: center;
    }

    &-heading {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 144.444%;
    }

    &-delete-all {
      padding: 4px;
      font-size: 1.7rem;
      font-weight: 500;
      color: #f44336;
      cursor: pointer;
      background-color: transparent;
    }
  }

  &__products + &__products {
    margin-top: 20px;
  }

  &__summary {
    padding-top: 24px;

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #f0efef;
    }

    &-price {
      color: var(--primary-color);
    }

    &-price,
    &-desc {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 144.444%;
      color: var(--primary-bg);
    }
  }

  &__bottom {
    padding: 24px;
    width: 100%;
    background-color: var(--white);
    box-shadow: 0 -2px 6px 0 rgba(28, 28, 28, 0.06);
    @include media-breakpoint-down(md) {
      padding: 16px;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    &-price {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.5;
      color: var(--coffee-color-v2);
    }
  }

  &__loading {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);

    &-icon {
      z-index: 999;
      color: #00ff6e;
      padding: auto 0;
    }
  }
}
