.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  font-size: 1.7rem;
  font-weight: 500;

  &__item-container {
    display: flex;
    align-items: center;
    gap: 10px;

    & a {
      color: var(--coffee-color);
    }
  }

  &__icon {
    display: block;
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }
}
