@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.terms-of-use {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--primary-text-color);

  & strong {
    font-weight: 600;
  }

  & li {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 15px;
    width: 85%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    &:first-child {
      margin-top: 0px;
    }

    & .check-icon {
      margin-top: 6px;
      width: 14px;
      height: 14px;
      color: var(--primary-color);
    }
  }
}
