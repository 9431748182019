@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.check-icon {
  margin-top: 5px;
  width: 14px;
  height: 14px;
  color: var(--primary-color);
}

.refresh-icon {
  width: 16px;
  height: 16px;
  transition: all linear 0.3s;
}

.strong {
  font-weight: 600;
  color: var(--primary-color);
}

.update-btn {
  width: 140px;
  height: 45px;
  font-weight: 500;
  color: var(--white);
  border-radius: 4px;
  background-color: var(--primary-bg);
  transition: all linear 0.3s;

  @include media-breakpoint-down(lg) {
    padding: 5px 10px;
    margin-top: 10px;
    width: 130px;
    font-size: 1.5rem;
  }
}

.isDisabled {
  opacity: 0.4;
  user-select: none;
  pointer-events: none;
}

.btn-check-icon {
  width: 24px;
  height: 24px;
}

.btn-power-icon {
  width: 20px;
  height: 20px;
}

.btn-power-off {
  color: var(--white);
  background-color: #ef5350;
}

.auth-twin {
  &-wrapper {
    padding-right: 30px;
    width: 100%;
    color: var(--text-color);

    @include media-breakpoint-down(lg) {
      padding-right: 0;
    }
  }

  &__title {
    margin-bottom: 12px;
    font-size: 1.8rem;
    font-weight: 600;
  }
}

.first-row {
  gap: 10px;

  &__desc {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-top: 10px;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 500;
  }
}

.security-method {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;

  &__title {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.5;
  }

  &__option-label {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--primary-color);

    @include media-breakpoint-down(lg) {
      display: block;
      margin: 10px 0 0;
      width: 100%;

      &:first-child {
        margin-top: 0;
      }
    }

    & input {
      margin-right: 10px;

      &:checked {
        color: var(--primary-color);
      }
    }
  }
}

.secret-key {
  &__container {
    margin-top: 10px;
  }

  &__first-row {
    display: flex;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  &__first-row {
    display: flex;
    align-items: center;
  }

  &__desc {
    display: flex;
    margin-right: 30px;
    font-size: 1.6rem;
    font-weight: 500;

    @include media-breakpoint-down(lg) {
      margin-right: 0;
    }
  }

  &__label {
    margin-top: 10px;
    font-size: 1.7rem;
    font-weight: 400;
  }

  &__content {
    width: 100%;
  }

  &__value-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: all;

    @include media-breakpoint-down(xxl) {
      flex-wrap: wrap;
      gap: 10px;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__value {
    font-size: 1.8rem;
    color: var(--primary-color);
    font-weight: 600;
    min-width: 60%;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      margin-top: 10px;
      width: 300px;
      text-align: center;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }

  &__refresh-btn {
    margin-left: 30px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid var(--text-color);
    transition: all linear 0.2s;

    & {
      p {
        transition: 0.2s linear;
        color: var(--text-color);
      }
    }

    &:hover {
      color: var(--primary-bg);
      border: 1px solid var(--primary-bg);

      & {
        p {
          color: var(--primary-bg);
        }
      }

      & .refresh-icon {
        color: var(--primary-bg);
      }
    }

    @include media-breakpoint-down(xxl) {
      margin-left: 0;
    }

    @include media-breakpoint-down(xl) {
      margin-left: 20px;
    }

    @include media-breakpoint-down(lg) {
      margin: 0 0 30px;
    }

    & p {
      font-weight: 600;
    }
  }

  &__qr-code {
    display: block;
    width: 200px;
    height: 200px;
    min-width: 200px;
    object-fit: contain;
    border-radius: 5px;
    background-color: #f6f6f6;
    border: 1px solid var(--primary-color);

    @include media-breakpoint-down(lg) {
      width: 270px;
      height: 270px;
    }
  }
}

.otp {
  &__container {
    display: flex;
    align-items: center;
    margin-top: 20px;

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.5;

    @include media-breakpoint-down(lg) {
      font-size: 1.7rem;
    }
  }

  &__input {
    padding: 10px;
    margin-left: 30px;
    width: 140px;
    height: 40px;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid var(--text-color);
    transition: all linear 0.3s;

    &:focus {
      color: var(--coffee-color-v2);
      border: 1px solid var(--primary-bg);
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
    }
  }

  &__btns {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    gap: 30px;
  }

  &__note {
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: auto;

    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.note {
  &__title {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.5;
  }

  &__desc {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    font-size: 1.6rem;
    font-weight: 500;
  }
}
