@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.conversation {
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: 40px;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 4px;
      padding: 4px;
    }

    &-avatar {
      width: 40px;
      height: 40px;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        width: 35px;
        height: 35px;
      }
    }

    &-title {
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
  }
}
