@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.sidebar {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 40%;
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  @include media-breakpoint-down(md) {
    padding: 10px 3px;
    width: 20%;
  }

  &__separate {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    color: var(--primary-bg);

    &-desc {
      margin-left: 0.5rem !important;
      padding-bottom: 10px !important;
      font-size: 1.4rem !important;
      text-align: center !important;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    max-height: 400px;
    overflow-y: auto;
    overflow-y: hidden;
  }
}
