@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.banner {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: 50%;

  @include media-breakpoint-down(md) {
    height: fit-content;
    background-image: none !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);

    @include media-breakpoint-down(md) {
      width: initial;
    }
  }

  &__content-wrapper {
    position: relative;
  }

  &__content {
    position: absolute;
    top: 50vh;
    left: 50%;
    right: 50%;
    padding: 10px;
    width: 100%;
    text-align: center;
    color: var(--white);
    translate: -50% -50%;
    border-radius: 6px;
    background-color: transparent;
    z-index: 2;

    @include media-breakpoint-down(md) {
      position: static;
      padding: 30px 0;
      text-align: left;
      color: var(--text-color);
      border: none;
      translate: 0;
    }
  }

  &__greeting,
  &__caption {
    text-shadow: -1px 0px 0px var(--primary-bg, #dbad6a), 0px 1px 0px var(--primary-bg, #dbad6a),
      1px 0px 0px var(--primary-bg, #dbad6a), 0px -1px 0px var(--primary-bg, #dbad6a);
  }

  &__greeting {
    font-size: 3rem;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
      font-weight: 700;
      text-shadow: initial;
    }
  }

  &__caption {
    margin-bottom: 10px;
    font-size: 4.2rem;
    font-weight: 600;

    @include media-breakpoint-down(lg) {
      font-size: 3.2rem;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      font-size: 2.4rem;
      font-weight: 700;
      color: var(--white);
    }
  }

  &__desc {
    font-weight: 500;
    line-height: 1.5;

    @include media-breakpoint-down(md) {
      font-style: italic;
      color: var(--primary-bg);
    }
  }

  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin: 30px 0 50px;

    @include media-breakpoint-down(md) {
      justify-content: flex-start;
      margin: 30px 0;
    }
  }

  &__search-container {
    position: relative;
    min-width: 300px;
    width: 50%;
    color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--white);

    @include media-breakpoint-down(lg) {
      width: 80%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      color: var(--primary-bg);
      border-color: var(--primary-bg);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 45px;
      display: block;
      width: 1px;
      height: calc(100% - 16px);
      background-color: var(--white);
      transform: translateY(-50%);

      @include media-breakpoint-down(md) {
        background-color: var(--primary-bg);
      }
    }

    & .search-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      display: block;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
      cursor: pointer;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7479%) hue-rotate(90deg) brightness(101%)
        contrast(96%);

      @include media-breakpoint-down(md) {
        width: 26px;
        height: 26px;
        filter: brightness(0) saturate(100%) invert(48%) sepia(10%) saturate(1877%) hue-rotate(77deg) brightness(96%)
          contrast(82%);
      }
    }

    & .close-icon {
      position: absolute;
      top: 50%;
      right: 55px;
      width: 18px;
      height: 18px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__input-search {
    display: block;
    padding-right: 32px;
    width: calc(100% - 50px);
    height: 45px;
    font-size: 1.6rem;
    text-indent: 15px;
    color: var(--white);
    background-color: transparent;

    @include media-breakpoint-down(md) {
      color: var(--primary-bg);
    }

    &::placeholder {
      color: var(--white);

      @include media-breakpoint-down(md) {
        color: var(--primary-bg);
      }
    }
  }

  &__search-spinning-icon {
    animation: spin 1s linear infinite;

    @keyframes spin {
      from {
        transform: translateY(-50%) rotate(0deg);
      }
      to {
        transform: translateY(-50%) rotate(360deg);
      }
    }
  }

  &__search-btn {
    display: block;
    margin-top: 10px;
    padding: 15px;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--white);
    border-radius: 6px;
    background-color: var(--primary-bg) !important;
    transition: background-color linear 0.3s;
    cursor: pointer;

    &:hover {
      background-color: #181818 !important;
    }
  }
}
