@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.restaurant-card {
  position: relative;
  padding: 0 7px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    padding: 0 5px;
  }

  &__label {
    position: absolute;
    top: 15px;
    padding: 3px 5px 3px 3px;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--white);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--coffee-color);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -3px;
      width: 5px;
      height: 100%;
      background-color: var(--coffee-color);
    }

    &-tail {
      position: absolute;
      left: -3px;
      bottom: -2px;
      width: 0;
      border-top: 3px solid var(--coffee-color);
      border-left: 3px solid transparent;
      z-index: -1;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
  }

  &__name {
    margin-top: 10px;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--text-color);

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
    }
  }

  &__categories {
    margin: 10px 0;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
    color: #676767;
  }

  &__rating {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
    color: #676767;
  }

  &__rating-container {
    display: flex;
    align-items: center;
    gap: 10px;

    @include media-breakpoint-down(md) {
      gap: 5px;
    }
  }

  &__discount-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 10px 0 20px;
  }

  &__discount-tag {
    width: 24px;
    height: 24px;

    @include media-breakpoint-down(md) {
      width: 20px;
      height: 20px;
    }
  }

  &__discount-text {
    @include media-breakpoint-down(md) {
      font-size: 1.4rem;
    }
  }
}

.star {
  &__container,
  &__item {
    display: flex;
    align-items: center;
  }
}

.restaurant-card .star-icon {
  width: 18px;
  height: 18px;
  color: #f7c942;

  @include media-breakpoint-down(md) {
    width: 14px;
    height: 14px;
  }
}
