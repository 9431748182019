@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.wallet {
  &__title {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.5;
  }

  &__copy-icon {
    width: 24px;
    height: 24px;
    filter: brightness(0) saturate(100%) invert(49%) sepia(19%) saturate(967%) hue-rotate(77deg) brightness(94%)
      contrast(89%);
  }

  &__transfer-content {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px auto 0;
      width: 50%;
      height: 45px;
      border-radius: 6px;
      border: 1px solid var(--primary-color);
      cursor: pointer;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }

    &-value {
      font-size: 1.8rem;
      font-weight: 600;
      color: var(--primary-color);
    }
  }

  &__logo-bank-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto 0;
    width: 50%;
    height: 45px;
    border-radius: 6px;
    background-color: var(--primary-bg);

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    & img {
      width: 20%;

      @include media-breakpoint-down(lg) {
        width: 24%;
      }
    }
  }

  &__qr-info {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    &-row {
      display: flex;
      gap: 5px;
      margin-top: 5px;
      font-size: 1.6rem;
      font-weight: 500;
    }

    &-value--green {
      font-weight: 600;
      color: var(--primary-color);
    }
  }

  &__qr-container {
    width: 32%;
    height: 32%;
    margin: 25px auto 0;
    border-radius: 8px;
    border: 1px solid var(--coffee-color-v2);
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      width: 70%;
      height: 70%;
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom;
    }
  }

  &__note {
    &-container {
      padding: 10px;
      margin-top: 25px;
      font-size: 1.7rem;
      font-weight: 500;
      color: var(--text-color);
      border-radius: 8px;
      background-color: rgb(255, 196, 0, 0.78);

      @include media-breakpoint-down(lg) {
        font-size: 1.6rem;
      }

      a {
        color: var(--primary-color);
      }
    }

    &-desc {
      display: flex;
      gap: 5px;
    }
  }
}
