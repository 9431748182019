@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.overlay-hidden {
  display: none;
  transition: all 0.5s;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.65);
}

.drawer-container {
  width: 30%;
  height: 100%;
  inset: 0;
  z-index: 99999;
  background-color: var(--white);
}

.product {
  &__wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 20px;
    height: 100%;
    border-radius: 5px;
    background-color: #f7f7f7;
    border: 1px solid transparent;
    transition: border-color, transform, box-shadow;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr 4fr;
    }

    &:hover {
      transform: translateY(-1px);
      border: 1px solid var(--primary-bg);
      box-shadow: 0px 0px 10px 0px rgba(40, 40, 40, 0.2);
    }
  }

  &__img {
    width: 100%;
    height: auto;
    min-width: 80px;
    min-height: 80px;
    object-fit: cover;
    border-radius: 6px;
    background-color: #f1f2f6;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-left: 20px;

    @include media-breakpoint-down(md) {
      margin-left: 15px;
    }
  }

  &__name {
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__desc {
    font-weight: 500;
    color: var(--secondary-text-color);
  }

  &__name,
  &__desc {
    display: block;
    display: -webkit-box;
    max-height: 3em;
    line-height: 1.5;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__last-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
  }

  &__price {
    font-size: 1.8rem;
    font-weight: 500;
  }

  &__add-cart-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--coffee-color);
  }
}
