.message {
  &__header {
    display: flex;
    align-items: end;
    margin: 0 0 10px 0;
    max-width: 60%;
    clear: both;

    &--left {
      float: left;
    }

    &--right {
      float: right;
    }
  }

  &__avatar {
    width: 28px;
    height: 28px;
  }

  &__content {
    margin: 0 0 0 10px;
    font-size: 17px;
    width: 100%;
    hyphens: auto;
    border-radius: 20px;
    word-break: break-word;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }

    &--img {
      padding: 7px 20px;
    }

    &--shop {
      background-color: #f0f0f0;
    }

    &--me {
      background-color: #00ba5133;
    }
  }

  &__time {
    display: block;
    font-size: 1.3rem !important;
    font-weight: 500 !important;
    color: var(--coffee-color-v2);
    clear: both;
    opacity: 0.5;

    &--left {
      margin: 0 0 20px 2px !important;
      text-align: left;
    }

    &--right {
      margin: 0 2px 20px 0 !important;
      text-align: right;
    }
  }
}
