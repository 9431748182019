@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.restaurant {
  &__header {
    padding: 120px 0 20px;
    background-image: url('../../assets/images/home/leaves_pattern.png');
    background-position: 50%;
    background-size: contain;
  
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__bg {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
      margin-bottom: 20px;
      width: 100%;
      height: 35vh;
      object-fit: cover;
      background-color: #f1f2f6;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    margin-top: 15px;
    font-size: 2.4rem;
    font-weight: 700;

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }

  &__chat {
    display: inline-flex;
    align-items: center;
    margin-left: 20px;
    font-weight: 600;
    color: var(--primary-bg);
    cursor: pointer;
  }

  &__desc {
    margin-top: 15px;
    font-size: 1.6rem;
  }

  &__rating {
    font-size: 1.6rem;
    color: #676767;

    &-container {
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 10px;

      @include media-breakpoint-down(md) {
        gap: 5px;
      }
    }
  }

  &__time-open {
    display: flex;
    gap: 50px;
    margin-top: 15px;

    @include media-breakpoint-down(md) {
      gap: 20px;
      padding-bottom: 20px;
    }
  }

  &__nav-container {
    position: -webkit-sticky;
    position: sticky;
    top: 85px;
    display: flex;
    margin-top: 10px;
    background-color: var(--white);
    box-shadow: 0 4px 6px 0 rgba(28, 28, 28, 0.06);
    z-index: 999;

    @include media-breakpoint-down(md) {
      top: 0;
      display: none;
    }
  }

  &__nav {
    display: flex;
    overflow: auto;

    &-item {
      flex-grow: 1;
      padding: 10px 30px;
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
      transition: all 0.5s;
      color: var(--secondary-text-color);
      border-bottom: 2px solid transparent;
      cursor: pointer;

      @include media-breakpoint-down(lg) {
        padding: 10px 20px;
      }

      @include media-breakpoint-down(md) {
        min-width: 50%;
      }

      &:hover {
        color: var(--primary-color);
      }

      &-active {
        color: var(--primary-color);
        border-bottom: 2px solid var(--primary-color);
      }

      &--only {
        flex-grow: initial;
      }
    }
  }

  &-body {
    padding-bottom: 50px;
    background-color: #f7f7f7;
  }

  &__group-product-container {
    padding-top: 35px;

    @include media-breakpoint-down(lg) {
      padding-top: 20px;
    }
  }

  &__group-product-name {
    margin-bottom: 35px;
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--primary-text-color);

    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }

  &__product {
    background-color: var(--white) !important;
  }
}

.star-container {
  display: flex;
  align-items: center;
}

.restaurant .star-icon {
  width: 18px;
  height: 18px;
  color: #f7c942;
  
  @include media-breakpoint-down(md) {
    width: 14px;
    height: 14px;
  }
}
