.item-container {
  margin: auto;
  padding-bottom: 10px;
  width: 100%;
  border-radius: 8px;
  background: rgb(238, 238, 238, 0.5);
}

.img {
  margin-bottom: 10px;
  width: 100%;
  min-height: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.title {
  margin-bottom: 10px;
  width: 60%;
  min-height: 30px;
}

.categories {
  margin-bottom: 10px;
  width: 80%;
  min-height: 20px;
}

.content {
  width: 100%;
  height: auto;
  min-height: 40px;
}

.promo {
  margin: 10px auto 0;
  width: 50%;
  height: 20px;
}

.placeholder {
  position: relative;
  border-radius: 8px;
  background: rgb(204, 204, 204, 0.3);
  overflow: hidden;
}

.placeholder:after {
  content: '';
  position: absolute;
  top: 0;
  left: -100px;
  width: 100px;
  height: 100%;
  animation: reflect 1000ms ease-out infinite;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.4), transparent);
}

@keyframes reflect {
  to {
    left: calc(100% + 100px);
  }
}

.order {
  &-item {
    &-container {
      padding: 20px;
      margin: 20px 0;
      width: 100%;
      border-radius: 5px;
      background-color: var(--white);
      box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.08);
    }

    &__shop-name {
      margin-bottom: 15px;
      width: 25%;
      min-height: 30px;
    }
  }

  &__product {
    &-info-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      margin-bottom: 20px;
      width: 100%;
    }

    &__img {
      width: 120px;
      height: 120px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      flex-grow: 1;
      flex-shrink: 0;
    }

    &-name {
      width: 40%;
      height: 30px;
    }

    &-desc {
      width: 80%;
      height: 60px;
    }

    &-quantity {
      width: 10%;
      height: 20px;
    }

    &-price {
      width: 10%;
      height: 30px;
    }

    &-content {
      height: 100px;
    }
  }
}
