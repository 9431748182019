@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.about {
  &__img {
    width: 100%;
    height: 480px;
    object-fit: cover;
    vertical-align: bottom;

    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }

  &__top {
    margin: 0px auto;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include media-breakpoint-down(lg) {
      padding: 30px 10px;
      text-align: left;
    }
  }

  &__title {
    margin: 0px auto 24px;
    font-size: 3rem;
    font-weight: 600;

    @include media-breakpoint-down(lg) {
      font-size: 2.5rem;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 18px;
      font-size: 2.2rem;
    }
  }

  &__desc {
    max-width: 950px;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2;
    color: #797b7c;
  }

  &__desc + &__desc {
    margin-top: 10px;
  }

  &__btn {
    margin-top: 40px;

    @include media-breakpoint-down(lg) {
      margin-top: 30px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &__intro {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      gap: 30px;
      width: 100%;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 25px;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      max-width: 621px;
      text-align: center;
      border-radius: 8px;
      background-color: var(--white);
      box-shadow: rgba(95, 95, 95, 0.1) 0px 2px 4px 1px;

      &--xl {
        max-width: 100%;
      }

      @include media-breakpoint-down(xxl) {
        min-height: 252px;
      }

      @include media-breakpoint-down(lg) {
        padding: 10px;
        min-height: 180px;
        text-align: left;
      }
    }

    &-title {
      margin-bottom: 8px;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.5;

      @include media-breakpoint-down(lg) {
        font-size: 2.2rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }

    &-desc {
      max-width: 950px;
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 2;
      color: #797b7c;

      @include media-breakpoint-down(lg) {
        font-size: 1.6rem;
      }
    }
  }

  &__img {
    &-wrapper {
      margin-top: 30px;

      @include media-breakpoint-down(lg) {
        margin-top: 20px;
      }
    }

    &-item {
      max-width: 396px;
      margin: 0 15px 30px;
      vertical-align: top;
      overflow: hidden;

      @include media-breakpoint-down(lg) {
        margin: 0 auto 30px;
      }
    }

    &-thumb {
      width: 100%;
      height: 248px;
      object-fit: cover;
      border-radius: 8px;

      @include media-breakpoint-down(md) {
        height: 215px;
      }
    }

    &-title {
      margin: 28px 0 8px;
      font-size: 2.2rem;
      font-weight: 600;
      line-height: 1.4;
      text-align: center;
      color: #202325;

      @include media-breakpoint-down(lg) {
        margin: 8px 0;
      }

      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }

    &-desc {
      padding: 0 10px;
      font-weight: 500;
      line-height: 2;
      color: #797b7c;
      text-align: center;

      @include media-breakpoint-down(lg) {
        line-height: 1.5;
        text-align: left;
      }
    }
  }

  &__main {
    margin: 100px auto;
    text-align: center;

    @include media-breakpoint-down(lg) {
      margin: 50px auto;
    }

    &-title {
      font-size: 2.6rem;
      font-weight: 600;
      line-height: 1.5;
      text-align: center;

      @include media-breakpoint-down(lg) {
        font-size: 2.4rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 2.2rem;
      }
    }
  }

  &__footer {
    position: relative;
    padding-bottom: 50px;
    width: 100%;
    height: 480px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;

    @include media-breakpoint-down(lg) {
      height: 360px;
      vertical-align: top;
    }

    &-text {
      position: relative;
    }

    &-quotes {
      position: absolute;
      top: -30px;
      left: -40px;

      @include media-breakpoint-down(md) {
        top: -25px;
        left: -5px;
        width: 2.5rem;
        height: 2.5rem;
      }

      &--close {
        rotate: 180deg;
        top: initial;
        left: initial;
        bottom: 0;
        right: 0;
      }
    }

    &-word {
      position: relative;
      width: 100%;
      padding: 0 19%;
      margin: 0 auto;
      font-size: 2.4rem;
      line-height: 2;
      color: var(--white);
      vertical-align: middle;
      display: inline-block;
      text-align: left;

      @include media-breakpoint-down(lg) {
        font-size: 1.8rem;
      }
    }

    &-bg {
      position: absolute;
      width: 100%;
      height: 480px;
      background-color: #4d8b55;
      z-index: -1;

      @include media-breakpoint-down(lg) {
        height: 360px;
      }
    }
  }

  &__contact {
    padding: 20px 50px 30px;
    margin: 30px 0 50px;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: rgba(95, 95, 95, 0.1) 0px 2px 4px 1px;

    @include media-breakpoint-down(lg) {
      padding: 30px;
    }

    @include media-breakpoint-down(md) {
      padding: 15px;
    }

    &-heading {
      font-size: 2.6rem;
      font-weight: 600;
      line-height: 1.5;
      text-align: center;

      @include media-breakpoint-down(lg) {
        font-size: 2.4rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 2.2rem;
      }
    }

    &-desc {
      margin: 15px auto 0;
      width: 70%;
      font-size: 1.7rem;
      line-height: 1.4;
      color: #79808a;
      text-align: center;

      @include media-breakpoint-down(lg) {
        margin-top: 10px;
        width: 100%;
        font-size: 1.6rem;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.journey-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.journey-item {
  position: relative;
  display: flex;
  min-height: 240px;
  padding-left: 27px;

  @include media-breakpoint-down(lg) {
    padding-bottom: 24px;
    flex-direction: column;
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    padding-left: 8px;
  }

  &:last-child &__line {
    height: 0;
  }

  &__img {
    width: 342px;
    height: 200px;
    object-fit: cover;

    @include media-breakpoint-down(lg) {
      padding-left: 15px;
      margin-bottom: 16px;
      width: 100%;
      height: auto;
    }
  }

  &__dot {
    margin: 0 48px;
    width: 9px;
    height: 9px;
    background-color: var(--primary-bg);
    border-radius: 50%;

    @include media-breakpoint-down(lg) {
      position: absolute;
      top: -5px;
      left: 0;
      margin: 0;
    }
  }

  &__line {
    width: 1px;
    margin-left: -53px;
    margin-right: 53px;
    content: "";
    background: var(--primary-bg);

    @include media-breakpoint-down(lg) {
      position: absolute;
      left: 4px;
      margin: 0;
      height: 100%;
    }
  }

  &__desc {
    width: 300px;

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      max-width: 342px;
      text-align: left;
    }

    & .title {
      margin-bottom: 16px;
      color: #202325;
      font-size: 2rem;
      line-height: 1.5;
      font-weight: 600;

      @include media-breakpoint-down(lg) {
        font-size: 1.8rem;
      }
    }

    & .text {
      color: #797b7c;
      line-height: 1.5;
      font-weight: 500;
    }
  }
}
