@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.chats {
  position: fixed;
  bottom: 15vh;
  right: calc(2vw - 2px);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  width: 52px;
  font-size: 1.4rem;
  z-index: 999;
  opacity: 0.9;
}

.chat-bot,
.chat {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  height: 50%;
  color: white;
  border-radius: 50%;
  background-color: var(--coffee-color-v2);
  border: 2px solid var(--text-color);
  cursor: pointer;

  &__icon {
    width: 28px !important;
    height: 28px !important;
  }
}

.chat {
  &__modal {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50%;
    transform: translateY(50%);
    display: flex;
    margin: 0 auto;
    width: 800px;
    height: calc(100vh - 60px);
    border-radius: 6px;
    background-color: var(--white);
    backdrop-filter: blur(10px);
    background-clip: padding-box;
    outline: none;

    @include media-breakpoint-down(lg) {
      right: 15px;
      width: calc(100% - 30px);
    }

    @include media-breakpoint-down(md) {
      right: 0;
      width: 100%;
      height: 100vh;
      border-radius: 0;
    }
  }
}
