@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.list-promo {
  margin-bottom: 40px;

  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }
}

.list-wrapper {
  max-height: 0;
  animation: showList 0.5s linear forwards;

  @keyframes showList {
    from {
      max-height: 0;
    }

    to {
      max-height: 500px;
    }
  }
}

.list-wrapper-show {
  max-height: 500px;
}

.list-slider {
  padding-left: 0;
  padding-right: 0;

  .next-arrow {
    top: 45%;
    right: -35px;
    height: 0;

    &::before {
      content: '\203A';
      font-size: 5rem;
      color: #4d8b55;
      background-color: transparent;
    }
  }
  
  .prev-arrow {
    left: -35px;
    top: 45%;
    height: 0;

    &::before {
      content: '\2039';
      font-size: 5rem;
      color: #4d8b55;
      background-color: transparent;
    }
  }
}
