@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.home {
  scroll-behavior: smooth;

  &__title-1,
  &__title-2,
  &__title-3,
  &__title-4 {
    font-size: 2.6rem;
    font-weight: 600;
    color: var(--text-color);

    &--highlight {
      color: var(--primary-color);
    }

    @include media-breakpoint-down(md) {
      font-size: 2rem;
      font-weight: 600;
    }
    &--highlight {
      color: var(--primary-color);
    }
  }

  &__title-1,
  &__title-2 {
    margin: 20px 0 30px;

    @include media-breakpoint-down(md) {
      margin: 20px 0;
    }
  }

  &__title-3 {
    margin-top: 50px;

    @include media-breakpoint-down(md) {
      margin-top: 15px;
    }
  }

  &__title-4 {
    margin: 30px 0;
  }

  &__sub-title {
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--text-color);

    @include media-breakpoint-down(md) {
      font-size: 1.7rem;
    }
  }

  &__faq-text {
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 1.4rem;
  }

  &__search-result-loading {
    opacity: 0;
  }

  &__search-result-show {
    height: fit-content;
    max-height: 1900px;
    opacity: 1;

    @include media-breakpoint-down(lg) {
      max-height: 5500px;
    }
  }

  &__search-result-hidden {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }

  &__search-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    max-height: 1300px;

    @include media-breakpoint-down(md) {
      margin-top: 150px;
      min-height: 200px;
      max-height: 1500px;
      align-items: flex-start;
    }
  }

  &__loading-icon {
    width: 80px !important;
    height: 80px !important;
    color: var(--secondary-text-color);
    animation: loading 0.5s infinite linear;

    @include media-breakpoint-down(md) {
      width: 50px !important;
      height: 50px !important;
    }
  }

  &__close-container {
    display: flex;
    align-items: center;
    padding: 14px;
    height: 40px;
    border: 2px solid var(--secondary-text-color);
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      height: 44px;
      width: fit-content;
    }
  }

  &__remove-title {
    position: relative;
    padding-right: 10px;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--secondary-text-color);
    transition: transform 0.5s;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      width: 1px;
      height: 100%;
      background-color: var(--secondary-text-color);
    }

    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
  }

  &__close-icon {
    margin-left: 5px;
    width: 18px;
    height: 18px;
    color: var(--secondary-text-color);
    transition: transform 0.5s;
  }

  &__search-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
      margin-top: 48px;
      margin-bottom: 20px;
    }
  }

  &__search-title {
    margin: 35px 0;
    font-size: 2.4rem;
    font-weight: 500;
    color: var(--primary-bg);

    @include media-breakpoint-down(md) {
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__btn {
    font-weight: 500 !important;
    
    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
      width: 100% !important;
      font-size: 1.4rem !important;
      border-radius: 5px !important;
    }
  }

  &__contact {
    display: block;
    padding: 100px 10px 120px;
    width: 100%;
    height: calc(100vh - 88px);
    background-image: url('../../assets/images/banner/cafe01.jpg');
    background-position: 50% 50%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;

    @include media-breakpoint-down(lg) {
      padding: 40px 10px;
      height: auto;
      min-height: 550px;
    }

    @include media-breakpoint-down(md) {
      min-height: initial;
      background-attachment: initial;
      background-position: 65% 50%;
    }

    &-container {
      position: relative;
    }

    &-copyright {
      position: absolute;
      left: 100%;
      width: 160px;
      height: 160px;
      transform: translateX(-100%);
      background-image: url('../../assets/images/home/copyright.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-content {
      display: flex;
      justify-content: center;
      align-content: stretch;
      flex-wrap: wrap;
      margin: 0 20px;
    }

    &-title {
      font-size: 3.5rem;
      line-height: 1.4;
      color: var(--white);

      &--primary {
        color: var(--primary-bg);
      }

      @include media-breakpoint-down(lg) {
        font-size: 3rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 2.4rem;
      }
    }

    &-desc {
      margin: 15px 0 40px;
      width: 50%;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.7;
      color: var(--white);

      @include media-breakpoint-down(lg) {
        width: 70%;
      }

      @include media-breakpoint-down(md) {
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  &__info {
    padding: 60px 10px;
    background-color: var(--primary-bg);

    @include media-breakpoint-down(lg) {
      padding: 60px 0;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: -20px;
    }

    &-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 20px;
      text-align: center;
      color: var(--white);
      border-right: 1px dotted var(--white);

      &--nb {
        border: none;
      }

      &--nbl {
        @include media-breakpoint-down(lg) {
          border: none;
        }
      }

      @include media-breakpoint-down(xl) {
        padding: 0 18px;
      }

      @include media-breakpoint-down(xl) {
        padding: 0 10px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
        padding: 0;
      }
    }

    &-img {
      display: block;
      margin-bottom: 10px;
      width: 106px;
      height: 94px;
      object-fit: cover;
    }

    &-title {
      margin-bottom: 5px;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2;

      @include media-breakpoint-down(md) {
        font-size: 1.8rem;
      }
    }

    &-desc {
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 1.6;

      @include media-breakpoint-down(md) {
        font-size: 1.6rem;
      }
    }
  }
}

.home__close-container:hover > .home__remove-title {
  color: rgb(244, 67, 54, 0.7);
  transform: translateX(-150%);
}

.home__close-container:hover > .home__remove-title::after {
  background-color: rgb(244, 67, 54, 0.7);
}

.home__close-container:hover {
  border: 2px solid rgb(244, 67, 54, 0.7);
}

.home__close-container:hover > .home__close-icon {
  margin-left: 0;
  color: rgb(244, 67, 54, 0.7);
  transform: scale(1.2) translateX(-100%);

  @include media-breakpoint-down(md) {
    transform: scale(1.5) translateX(-15 0%);
  }
}

.reason-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

.reason-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  text-align: left;

  &__check-icon {
    flex-shrink: 0;
    margin-top: 5px;
    width: 12px;
    height: 12px;
    color: var(--primary-color);
  }

  &__keyword {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--text-color);
  }

  &__text {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--text-color);
  }
}

// footer banner

.banner-footer {
  padding: 108px 0;
  background-image: url('../../assets/images/home/paper_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  @include media-breakpoint-down(md) {
    padding: 48px 0;
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 14px;
    text-align: center;

    &-img {
      margin: 0 auto;
      width: 130px;
      height: 130px;
    }
  }

  &__left {
    @include media-breakpoint-down(md) {
      margin-bottom: 48px;
    }
  }

  &__title {
    margin: 25px 0 12px;
    font-size: 2.2rem;
    font-weight: 700;

    @include media-breakpoint-down(lg) {
      font-size: 2rem;
    }
  }

  &__desc {
    margin-bottom: 24px;
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__logo-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
  }
}
