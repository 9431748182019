@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.no-result {
  margin-top: 30px;
  margin-bottom: 50px;
  user-select: none;
  pointer-events: none;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: fit-content;
  }

  &__title {
    margin-top: 8px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.5;

    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
    }
  }

  &__desc {
    margin-top: 10px;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--primary-bg);
  }

  &__img {
    margin: 0 auto;
    width: 120px;

    @include media-breakpoint-down(lg) {
      width: 100px;
    }
  }

  &__content {
    text-align: center;
  }
}
