@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.wrapper {
  margin: 120px 0 50px;

  @include media-breakpoint-down(lg) {
    margin-top: 150px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 35px;
  }
}

.errors-message {
  margin-top: 5px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #f44336;
  user-select: none;
}

.isError {
  border: 1px solid rgb(244, 67, 54) !important;
}

.icon {
  display: block;
  width: 16px !important;
  height: 16px !important;
  color: var(--text-color);
  transition: 0.5s;
}

.special-icon {
  display: none;
}

.btn-container {
  display: flex;
  gap: 20px;
  margin-left: auto;
  font-weight: 500;

  @include media-breakpoint-down(xl) {
    margin-top: 50px;
  }

  @include media-breakpoint-down(md) {
    justify-content: space-between;
    width: 100%;
  }
}

.cancel-btn,
.update-btn,
.change-btn {
  width: 140px;
  height: 45px;
  font-weight: 500;
  border-radius: 8px;
  transition: all linear 0.2s;
}

.cancel-btn {
  background-color: transparent;
  border: 1px solid var(--text-color);

  &:hover {
    color: var(--primary-bg);
    border-color: var(--primary-bg);
  }
}

.update-btn,
.change-btn {
  margin-left: auto;
  color: var(--white);
  background-color: var(--primary-bg);

  &:hover {
    opacity: 0.9;
  }
}

.select-image-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 1.5rem;
  width: 100%;
  height: 50%;
  transform: translateY(100%);
  transition: transform linear 0.3s;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: rgb(246, 246, 246, 0.4);
  clip-path: circle(100% at 50% 100%);
  cursor: pointer;

  &:hover {
    background-color: rgb(246, 246, 246, 0.6);
  }
}

.loader {
  width: 100px !important;
  height: 100px !important;
}

.onLoader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-personal-info {
  grid-template-columns: 1fr !important;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  display: block;
  width: 24px;
  height: 24px;
  color: var(--primary-color);
  cursor: pointer;
}

.profile {
  &__img-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 30px 15px;
    width: 100%;
    color: var(--white);
    background-color: #f6f6f6;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0 20px 60px 10px rgba(237, 237, 246, 0.2);
    background: linear-gradient(180deg, rgba(26, 22, 46, 0), #1a162e),
      url('../../assets/images/user-background.png') no-repeat 50% / cover;
    overflow: hidden;
  }

  &__sub-row {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__avatar-container {
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    transition: all 0.5s;
    overflow: hidden;

    &:hover .select-image-btn {
      transform: translateY(0);
    }
  }

  &__select-img-title {
    margin-top: 20px;
  }

  &__img {
    width: 140px;
    height: 140px;
    min-height: 140px;
    min-width: 140px;
    object-fit: cover;
    border-radius: 50%;
    background: #ccc content-box;
    border: 5px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.08);

    &-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      z-index: 999;
    }

    &-cover {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(1px);
      inset: 0;
    }
  }

  &__user-name {
    margin-top: 10px;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--coffee-color);
    transition: all linear 0.3s;
  }

  &__registered-day,
  &__coin {
    margin-top: 5px;
    font-size: 1.6rem;
    transition: all linear 0.3s;
  }

  &__registered-day {
    font-weight: 500;
  }

  &__coin {
    font-weight: 600;
  }

  &-nav-container {
    padding-bottom: 15px;
    color: var(--text-color);
    background-color: #f6f6f6;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    @include media-breakpoint-down(xl) {
      padding-bottom: 20px;
      margin-bottom: 15px;
    }
  }

  &-nav__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 500;
    background: transparent;
    border: 1px solid transparent;
    transition: all linear 0.2s;
    cursor: pointer;

    &-active {
      color: var(--primary-color);
      border-color: var(--primary-color);

      .icon {
        color: var(--primary-color);
      }
    }

    &:hover {
      color: var(--primary-color);
    }

    &:hover .icon {
      color: var(--primary-color);
    }
  }

  &-nav__title {
    margin-top: 10px;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: default;
    background: transparent;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &-content__title {
    font-size: 2rem;
    font-weight: 600;
  }

  &-content-container {
    position: relative;
    padding: 20px 20px 50px;
    height: 100%;
    color: var(--text-color);
    border-radius: 12px;
    background-color: #f6f6f6;

    @include media-breakpoint-down(xl) {
      min-height: 50vh;
    }

    @include media-breakpoint-down(md) {
      padding: 15px 15px 20px;
    }
  }

  &-input-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-down(lg) {
      row-gap: 15px;
      grid-template-columns: 1fr;
    }

    @include media-breakpoint-down(md) {
      row-gap: 10px;
    }
  }

  &-input-group {
    position: relative;
    width: 100%;
    transition: all linear 0.2s;

    &__label {
      position: relative;
      display: block;
      font-size: 1.6rem;
      font-weight: 500;
    }

    & input[type='text'],
    input[type='password'] {
      margin-top: 5px;
      padding: 10px;
      width: 100%;
      height: 44px;
      font-size: 1.6rem;
      font-weight: 500;
      border-radius: 5px;
      transition: all linear 0.3s;
      border: 1px solid var(--secondary-text-color);

      &:focus {
        border: 1px solid var(--primary-bg);
      }
    }
  }
}

.gender {
  &-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    margin-top: 5px;
    width: 80%;
    height: 44px;
    border-radius: 5px;
    border: 1px solid var(--text-color);
    transition: all linear 0.2s;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    &--open {
      border: 1px solid var(--primary-bg);
    }
  }

  &__selected-value {
    font-size: 1.6rem;
  }

  &__icon {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    rotate: 0deg;
    transition: all linear 0.3s;

    &--open {
      rotate: 180deg;
      filter: brightness(0) saturate(100%) invert(49%) sepia(12%) saturate(1526%) hue-rotate(76deg) brightness(94%)
        contrast(86%);
    }
  }

  &__options {
    position: absolute;
    left: 0;
    bottom: -94px;
    display: none;
    width: 100%;
    border-radius: 5px;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px,
      rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    overflow: hidden;
  }

  &__option {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 45px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    overflow: hidden;
    transition: all linear 0.2s;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      background-color: #d8f0df;
      color: var(--primary-bg) !important;
    }
  }
}

.change-pass {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  width: 50%;

  @include media-breakpoint-down(lg) {
    width: 100%;
    gap: 15px;
  }
}

.isShow {
  display: block;
}

.no-change {
  color: #bababa;
  user-select: none;
  pointer-events: none;

  & .gender {
    &-container {
      border: 1px solid #bababa !important;
    }

    &__icon {
      color: #bababa !important;
    }

    &__selected-value {
      color: #bababa !important;
    }
  }

  & input[type='text'],
  input[type='password'] {
    border: 1px solid #bababa !important;

    &::placeholder {
      color: #bababa !important;
      opacity: 1;
    }
  }
}

.date-picker {
  padding: 10px;
  width: 100%;
  height: 42px;
  border-radius: 10px;
  border: 1px solid var(--text-color);

  &.no-change {
    border: 1px solid #bababa !important;
  }
}
