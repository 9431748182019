@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.item-wrapper {
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 6px;
  background-color: var(--white);
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.08);

  @include media-breakpoint-down(md) {
    padding: 10px;
  }
}

.item {
  &__shop-info {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.5;
    border-bottom: 1px dashed #ccc;

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
    }
  }

  &__shop-name {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--coffee-color-v2);
  }

  &__status {
    color: var(--status-color, var(--primary-color));
    cursor: pointer;

    &--red {
      color: #f44336;
    }
  }
}

.sub-item {
  &__info-container {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px dashed #ccc;

    @include media-breakpoint-down(md) {
      padding: 15px 0;
    }
  }

  &__img {
    flex-shrink: 0;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #e1e1e3;
    background-color: #f1f2f6;

    @include media-breakpoint-down(lg) {
      width: 100px;
      height: 100px;
    }

    @include media-breakpoint-down(md) {
      width: 80px;
      height: 80px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 20px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;

    @include media-breakpoint-down(md) {
      margin-left: 10px;
    }
  }

  &__name,
  &__desc {
    display: block;
    display: -webkit-box;
    line-height: 1.5;
    max-height: 6em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  &__name {
    width: 80%;
    font-size: 1.7rem;
    font-weight: 600;

    @include media-breakpoint-down(lg) {
      width: 95%;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
    }
  }

  &__desc {
    width: 80%;
  }

  &__quantity {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.4;
    font-family: 'Inter', sans-serif;
    color: var(--primary-bg);
  }

  &__price {
    flex-shrink: 0;
    margin: auto 0;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--primary-color);

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
    }
  }
}

.note,
.payment-method,
.address,
.date-create {
  &__container {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__label {
    min-width: 100px;
    color: var(--primary-bg);
  }

  &__value {
    display: block;
    display: -webkit-box;
    max-height: 6em;
    line-height: 1.5;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}

.total {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;

    @include media-breakpoint-down(md) {
      gap: 10px;
    }

    &-btn {
      margin-left: auto;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--primary-color);

    @include media-breakpoint-down(md) {
      font-size: 1.7rem;
    }
  }
}
